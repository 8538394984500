<template>
    <modal ref="modalEntregar" titulo="Ordenar entrega de pedidos" no-aceptar adicional="Ordenar">
        <div v-for="(or, o) in ordenar_pedidos" :key="o" class="row mx-0 align-items-center mb-3">
            <div class="col-auto d-flex justify-center">
                <input v-model="pedido" :value="or.pedidos" type="radio" class="option-input black radio" />
            </div>
            <div class="col px-0 text-general">
                <i :class="`icon-${or.icon} text-general f-20`" />
                <span class="ml-3">{{ or.texto }}</span>
            </div>
        </div>
        <div class="row mx-0 justify-center mt-4">
            <div class="col-8">
                <p class="pl-3 text-general f-14">Ordenar a partir del pedido</p>
                <el-select v-model="value" class="w-100" placeholder="Seleccionar pedido">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    />
                </el-select>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return{
            pedido: 1,
            ordenar_pedidos: [
                {
                    pedidos: 1,
                    icon: 'calendar-clock',
                    texto: 'Fecha y hora de entrega'
                },
                {
                    pedidos: 2,
                    icon: 'package-variant-closed',
                    texto: 'Peso del pedido (De menor a mayor)'
                },
                {
                    pedidos: 3,
                    icon: 'package-variant-closed',
                    texto: 'Peso del pedido (De mayo a menor)'
                },
                {
                    pedidos: 4,
                    icon: 'ruler',
                    texto: 'Distancia del pedido (Más lejano al mas cercano)'
                },
                {
                    pedidos: 5,
                    icon: 'ruler',
                    texto: 'Distancia del pedido (Más cercano al mas lejado)'
                },
            ],
            options: [{
                value: 'Option1',
                label: 'Option1'
            }, {
                value: 'Option2',
                label: 'Option2'
            }, {
                value: 'Option3',
                label: 'Option3'
            }, {
                value: 'Option4',
                label: 'Option4'
            }, {
                value: 'Option5',
                label: 'Option5'
            }],
            value: ''
        }
    },
    methods: {
        toggle(){
            this.$refs.modalEntregar.toggle();
        }
    }
}
</script>